import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import { List, Grid, ListSubheader } from "@mui/material";
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { AppState } from "../../../redux/store";
import { fontColor } from "../../../constants/ColorSets";
import { isCurrentAccountOrgManager } from "../AppAuthProvider";
import { RuleUnitState } from "../../../types/models/RuleUnitState";
import { TeamMembersState } from "../../../types/models/TeamMembersState";
import NavigationItem from "../../NavigationItem/NavigationItem";
import AppLogo from "../AppLogo";
import UserInfo from "../UserInfo";
import { LogoutDialog } from "../../LogoutDialog";
import { useTeams } from "../../../hooks/useTeams";

const SidebarContent: React.FC = () => {
  const [isOpenLogoutDialog, setIsOpenLogoutDialog] = useState<boolean>(false);
  const [hasUnreadAlerts, setHasUnreadAlerts] = useState<boolean>(false);
  const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
  const teamState = useSelector<AppState, TeamMembersState>((state) => state.teamMembers);
  const isOrgManager = isCurrentAccountOrgManager();
  const { getProgressForTeam, filterAlertProgress } = useTeams();

  useEffect(() => {
    if(isOrgManager) {
      teamState.orgProgress?.map((orgProg) => {
        const userProgress = filterAlertProgress(orgProg.progress ?? [], orgProg.roles ?? [], orgProg.locations ?? []);
        userProgress?.forEach((prog) => {
          if(!prog.Resolved) {
            if(prog.SenderId !== null && prog.SenderId === prog.UserId && prog.MessageCount && prog.MessageCount > 0) {
              setHasUnreadAlerts(true);
            }
            else if(prog.SenderId === null && prog.MessageCount === 1) {
              setHasUnreadAlerts(true);
            }
          }
        });
      });
    }
    else if(!isOrgManager) {
      if(teamState.myTeams && teamState.myTeams.length > 0) {
        teamState.myTeams.forEach((team) => {
          getProgressForTeam(team.TeamId, (teamProgress) => {
            teamProgress.map((tProg) => {
              const userProgress = filterAlertProgress(tProg.progress ?? [], tProg.roles ?? [], tProg.locations ?? []);
              userProgress?.forEach((prog) => {
                if(!prog.Resolved) {
                  if(prog.SenderId !== null && prog.SenderId === prog.UserId && prog.MessageCount && prog.MessageCount > 0) {
                    setHasUnreadAlerts(true);
                  }
                  else if(prog.SenderId === null && prog.MessageCount === 1) {
                    setHasUnreadAlerts(true);
                  }
                }
              })
            })
          })
        })
      }
      else {
        setHasUnreadAlerts(false);
      }
    }

  }, [teamState.orgProgress, teamState.myTeams])

  return (
    <>
      <Grid className="app-side-bar">
        <Box sx={{
          position: "relative",
          paddingY: 3,
          paddingX: 2
        }}>
          <AppLogo />
        </Box>
        <List
          sx={{
            position: "relative",
            padding: 0,
          }}
          component="div"
        >
          <NavigationItem
            item={{
              id: "rules",
              title: "Rules",
              type: "item",
              icon: <DescriptionOutlinedIcon />,
              url: "/rules"
            }}
            level={0} />
          <NavigationItem
            item={{
              id: "alerts",
              title: "Alerts",
              type: "item",
              icon: <NotificationsIcon />,
              url: "/alerts",
              count: ruleUnitState.alertRules?.length
            }}
            level={0} />
          {(isOrgManager || (teamState.myTeams && teamState.myTeams.length > 0) ||
            (teamState.orgProgress && teamState.orgProgress.some(member => member.user_id !== ruleUnitState.userShieldId))
          ) && (
              <>
                <ListSubheader sx={{ background: "transparent", color: fontColor.subTitleFontColor }}>Management</ListSubheader>
                <NavigationItem
                  item={{
                    id: "teams",
                    title: "Your Teams",
                    type: "item",
                    icon: <GroupsOutlinedIcon />,
                    url: "/your-teams",
                    alert: hasUnreadAlerts,
                    active: window.location.href.includes("your-teams"),
                  }}
                  level={0} />
              </>
            )}
          {isOrgManager &&
            <NavigationItem
              item={{
                id: "all_teams",
                title: "Manage Teams",
                type: "item",
                icon: <ContactPageOutlinedIcon />,
                url: "/all-teams",
              }}
              level={0} />
          }
        </List>
        <Box className="app-side-bar-footer">
          <Box sx={{
            mx: 1.5,
            mb: 1.5
          }} >
            <UserInfo onLogoutRequest={() => setIsOpenLogoutDialog(true)} />
          </Box>
        </Box>
      </Grid>
      <LogoutDialog
        open={isOpenLogoutDialog}
        onDeny={() => setIsOpenLogoutDialog(false)} />
    </>
  );
};

export default SidebarContent;
