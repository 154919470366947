import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { getOrgProgress, getUsers } from "../../redux/actions";
import { theme } from "../../themes/theme";
import { VirtualTeam } from "../../types/models/Team";
import { AppState } from "../../redux/store";
import { TeamMembersState } from "../../types/models/TeamMembersState";
import { getOrgNameFromToken } from "../../components/AppBase/AppAuthProvider";
import AppInfoView from "../../components/AppBase/AppInfoView";
import AppLoader from "../../components/AppBase/AppLoader";
import YourTeamHeader from "./YourTeamHeader";
import MemberTable from "./MemberTable";
import EditTeam from "../all-teams/EditTeam";
import EmptyResult from "../rules/EmptyResult";
import { RuleUnitState } from "../../types/models/RuleUnitState";
import { CSVObject } from "../../types/models/CSVObject";
import { TeamMemberProgress } from "../../types/models/TeamMember";

export const MyTeamId = "my-team";

export default function YourTeamsPage() {
    const dispatch = useDispatch();
    const teamState = useSelector<AppState, TeamMembersState>((state) => state.teamMembers);
    const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
    const alertDetailsList = ruleUnitState.baseAlertRules?.concat(ruleUnitState.baseAlertHistories ?? []).map(rule => rule);
    const [loading, setLoading] = useState<boolean>(false);
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [selectedTeam, setSelectedTeam] = useState<VirtualTeam | undefined>({TeamId: MyTeamId, DisplayName: "", StartDate: "", EndDate: "", Members: []});
    const [targetTeamProgress, setTargetTeamProgress] = useState<TeamMemberProgress[] | undefined>();
    const [displayTeamProgress, setDisplayTeamProgress] = useState<TeamMemberProgress[] | undefined>();
    const [displayAllTeamMember, setDisplayAllTeamMember] = useState<boolean>(false);
    const [fullTeamProgress, setFullTeamProgress] = useState<CSVObject[]>([]);
    const [csvHeaders, setCsvHeaders] = useState<Array<{ label: string, key: string }>>([]);

    const formatDateTime = (dateTimeString: string | null, locale = 'en-AU') => {
        if (dateTimeString) {
            const date = new Date(dateTimeString);
            if (isNaN(date.getTime())) {
                // Handle invalid date string by returning the original string
                return dateTimeString;
            }

            return new Intl.DateTimeFormat(locale, {
                year: 'numeric', month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            }).format(date);
        }
        return dateTimeString;
    };

    useEffect(() => {
        const getDataForDownload = () => {
            let teamProgress: TeamMemberProgress[] | undefined = undefined;
            //Get users organisation name from token;
            const orgName = getOrgNameFromToken();
            //if current team is the org team
            if(!selectedTeam || selectedTeam?.TeamId === MyTeamId) {
                //Loop through the org progress
                teamProgress = teamState.orgProgress?.map((user) => {
                    //Get the matching ruleinstances from the userProgress using the alertdetailsList array
                    const updatedProgress = user.progress?.map((progressObj) => {
                        const matchingRule = alertDetailsList?.find(
                            (rule) => progressObj.RuleInstanceId === rule.RuleInstanceId
                        );
                        //if we get a matching rule we return the progress object with the new display name
                        //else we just return the progress obj
                        return matchingRule
                            ? { ...progressObj, DisplayName: matchingRule.DisplayName }
                            : progressObj;
                    });
                    // if we get the updatedProgress we return the user object with the updatedProgress passed to the progress prop
                    // else we return the user object.
                    return updatedProgress
                        ? { ...user, progress: updatedProgress }
                        : user;
                });
            }
            // if the current team is a virtual team
            else {
                // Get the current team emails
                const currentTeam = selectedTeam?.Members.map((member) => member.Email.toLowerCase());

                // Filter the organization progress by the current team's emails
                const selectedTeamProgress = teamState.teamProgressList?.find((team) => team.TeamId === selectedTeam.TeamId);

                // Filter only the users whose emails match those in the current team
                const filteredTeam = selectedTeamProgress?.Progress.filter((prog) => currentTeam?.includes(prog.email.toLowerCase()));

                // Assign the updated user list to the teamProgress variable
                teamProgress = filteredTeam;
            }

            // Create headers that are going to present in the CSV file
            // Typo "RecievedAt" present in API response, but retained for consistency.
            const headers = [
                { label: "Organisation", key: "Organisation" }, { label: "Email", key: "Email" },
                { label: "Display Name", key: "DisplayName" }, {label: "User Roles", key: "Roles"}, { label: "Alert Created At", key: "AlertCreatedAt" },
                { label: "Received At", key: "RecievedAt" }, { label: "Read At", key: "ReadAt" },
                { label: "Message Count", key: "DisplayMessageCount" }, { label: "Acknowledge At", key: "AcknowledgeAt" },
                { label: "Last Sync Start Date", key: "LastSyncStartDate" }
            ];   
            // Set the headers to state
            setCsvHeaders(headers);

            // Add the roles to the team progress object
            const teamProgressWithRoles = teamProgress?.map(obj => ({
                ...obj,
                progress: obj.progress?.map(progressItem => ({
                    ...progressItem,
                    Roles: obj.roles,
                    DisplayName: obj.name || "",
                }))
            }));
            // Filter the progress array to not include any null or undefined values
            // As well as flatten it to a single array
            const filteredTeamProgress = teamProgressWithRoles?.map((item) => item.progress).filter((item) => item !== null && item !== undefined).flat(1) as CSVObject[];
            // Loop through the newly filtered progress array and add the required props needed for the CSV
            filteredTeamProgress?.forEach((progressObj) => {
                if (progressObj) {
                    progressObj.Organisation = orgName ?? "",
                    progressObj.AcknowledgeAt = formatDateTime(progressObj.AcknowledgeAt);
                    progressObj.AlertCreatedAt = formatDateTime(progressObj.AlertCreatedAt);
                    progressObj.LastSyncStartDate = formatDateTime(progressObj.LastSyncStartDate);
                    progressObj.ReadAt = formatDateTime(progressObj.ReadAt);
                    progressObj.RecievedAt = formatDateTime(progressObj.RecievedAt);
                }
            });
            // Filter the progress array to not include undefined values
            // If roles are present have them displayed with commas separating them and show "No roles" if no roles are present
            const finalTeamProgress = filteredTeamProgress?.filter((item) => item !== undefined).map((obj) => ({
                ...obj,
                Roles: obj.Roles !== null ? `(${obj.Roles.join(', ')})` : "No roles",
            })) as unknown as CSVObject[];
            // Set the data used for the CSV to state
            setFullTeamProgress(finalTeamProgress);
        }
        getDataForDownload();
    }, [teamState.orgProgress, selectedTeam, teamState.teamProgressList])

    useEffect(() => {
        setLoading(true);
        if(selectedTeam?.TeamId === MyTeamId) {
            dispatch(getUsers(() => {
              dispatch(getOrgProgress(() => {
                setLoading(false);
              }));            
            }));
          } else if (!teamState.users) {
                dispatch(getUsers(() => {
                    setLoading(false);
                }));
        }
    }, []);

    // const getHeaderActionElement = () => {
    //     if (isCurrentAccountOrgManager() && (selectedTeam && (selectedTeam.TeamId !== MyTeamId))) {
    //         return (
    //                 <Button
    //                     startIcon={<EditIcon />}
    //                     variant="text"
    //                     onClick={() => {
    //                         setOpenEditModal(true);
    //                     }}
    //                     sx={{ width: "130px", textTransform: "none", borderRadius: 1 }}>
    //                         Edit Team
    //                 </Button>
    //         );
    //     } else {
    //         return undefined;
    //     }
    // };

    return (
        <>
            {loading && <Box sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <AppLoader />
            </Box>}
            <Box sx={{ backgroundColor: theme.page.bgColor, minHeight: "100vh" }}>
                <YourTeamHeader
                    teamName={selectedTeam?.DisplayName}
                    loading={loading}
                    title="Team Members"
                    // action={getHeaderActionElement()}
                    onSelectTeam={(teamId) => {
                        if (teamId === MyTeamId) {
                            // Select "My Team"
                            setDisplayAllTeamMember(true);
                            setSelectedTeam(undefined);
                        }
                        else {
                            setDisplayAllTeamMember(false);
                            setSelectedTeam(teamState.myTeams?.find(team => team.TeamId === teamId))
                        }
                    }
                    }
                    teamData={teamState.teamProgressList}
                    TeamProgressData={fullTeamProgress}
                    csvHeaders={csvHeaders}
                />
                <Box sx={{ m: 2 }}>
                    {(!loading && teamState.myTeams) && (
                        (selectedTeam || displayAllTeamMember) ?
                            <MemberTable allMemberProgress={displayAllTeamMember} team={selectedTeam} onOpenEditDialog={() => setOpenEditModal(true)} displayTeamProgress={displayTeamProgress} setDisplayTeamProgress={setDisplayTeamProgress} targetTeamProgress={targetTeamProgress} setTargetTeamProgress={setTargetTeamProgress} />
                            : <EmptyResult>No team selected</EmptyResult>
                    )
                    }
                </Box>
            </Box>
            <EditTeam
                team={selectedTeam}
                isOpen={openEditModal}
                onClose={() => setOpenEditModal(false)}
                allUsers={teamState.users ?? []}
                setTargetTeamProgress={setTargetTeamProgress}
                isYourTeamsPage={true}
            ></EditTeam>
            <AppInfoView />
        </>
    );
}
