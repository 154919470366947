import React, { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { Box, Button, Hidden, IconButton, MenuItem, Select, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Download } from "@mui/icons-material";
import { toggleNavCollapsed } from "../../redux/actions/Setting";
import { fontColor } from "../../constants/ColorSets";
import { theme } from "../../themes/theme";
import { AppState } from "../../redux/store";
import { TeamMembersState } from "../../types/models/TeamMembersState";
import { Fonts } from "../../constants/AppEnums";
import { isCurrentAccountOrgManager } from "../../components/AppBase/AppAuthProvider";
import { MyTeamId } from ".";
import { CSVObject } from "../../types/models/CSVObject";
import { RuleUnitState } from "../../types/models/RuleUnitState";
import { TeamProgressModel } from "../../types/models/TeamProgressModel";

interface TeamPageHeaderProp {
    teamName: string | undefined;
    loading: boolean;
    title: string,
    action?: ReactElement,
    onSelectTeam: (teamId: string) => void,
    TeamProgressData: CSVObject[];
    csvHeaders: Array<{ label: string, key: string }>;
    teamData: TeamProgressModel[] | undefined;
}

const YourTeamHeader: React.FC<TeamPageHeaderProp> = (prop) => {
    const { team_id } = useParams();
    const dispatch = useDispatch();
    const teamState = useSelector<AppState, TeamMembersState>((state) => state.teamMembers);
    const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
    const [selectedTeamId, setSelectedTeamId] = useState<string>("");
    const isOrgManager = isCurrentAccountOrgManager();
    const havingOtherMemberProgress = (teamState.orgProgress && teamState.orgProgress.some(member => member.user_id !== ruleUnitState.userShieldId));

    useEffect(() => {
        if (team_id) {
            if (team_id == MyTeamId && (isOrgManager || havingOtherMemberProgress)) {
                setSelectedTeamId(MyTeamId);
                prop.onSelectTeam(MyTeamId);
            } else {
                const team = teamState.myTeams?.find(team => team.TeamId === team_id);
                if (team) {
                    // Select team if its id is specified in URL
                    setSelectedTeamId(team_id);
                    prop.onSelectTeam(team_id);
                }
            }
        } else {
            // Initial selection
            if (isOrgManager || havingOtherMemberProgress) {
                setSelectedTeamId(MyTeamId);
                prop.onSelectTeam(MyTeamId);
            }
            else {
                if (teamState.myTeams && teamState.myTeams.length > 0) {
                    setSelectedTeamId(teamState.myTeams[0].TeamId);
                    prop.onSelectTeam(teamState.myTeams[0].TeamId);
                }
            }
        }
    }, [teamState.myTeams, team_id]);

    const handleChange = (teamId: string) => {
        setSelectedTeamId(teamId);
        prop.onSelectTeam(teamId);
    };

    const getTeamsMenus = () => {
        const menus: ReactElement[] = [];
        // A KiwiRail user can have progress data even if they are not an OrgManager and not a leader
        // In this case, we will show My Team to display the results.
        if (isOrgManager || havingOtherMemberProgress)
            menus.push(
                <MenuItem
                    key={MyTeamId}
                    value={MyTeamId}>
                    <Box sx={{ fontWeight: Fonts.SEMI_BOLD }}>Org Team</Box>
                </MenuItem>
            );

        teamState.myTeams?.forEach(team => {
            menus.push(
                <MenuItem
                    key={team.TeamId}
                    value={team.TeamId}>
                    {team.DisplayName + (team.StartDate ? ` (${team.StartDate} - ${team.EndDate})` : "")}
                </MenuItem>
            );
        });
        return menus;
    };

    return (
        <Box sx={{ borderRadius: 0 }}>
            <Box sx={{ mt: 2, ml: 1.5 }}>
                <Select
                    sx={{
                        color: theme.palette.primary.main,
                        fontWeight: Fonts.BOLD,
                        ':before': { borderBottomColor: 'transparent' },
                        ':after': { borderBottomColor: 'transparent' },
                        '&&:hover::before': { borderBottomColor: theme.palette.primary.main },
                    }}
                    variant="standard"
                    value={selectedTeamId}
                    onChange={(event) => handleChange(event.target.value)}>
                    {getTeamsMenus()}
                </Select>
            </Box>
            <Box sx={{
                height: 40,
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between"
            }}>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start"
                    }}>
                    <Hidden lgUp>
                        <IconButton
                            sx={{
                                color: "text.secondary",
                                marginLeft: 1,
                                padding: 0,
                            }}
                            edge="start"
                            className="menu-btn"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => dispatch(toggleNavCollapsed())}
                            size="large">
                            <MenuIcon
                                sx={{
                                    width: 35,
                                    height: 35,
                                }}
                            />
                        </IconButton>
                    </Hidden>
                    <Box sx={{ py: 1 }}>
                        <Typography
                            variant="h3"
                            component="h3"
                            color={fontColor.titleFontColor}
                            style={{ fontSize: 22, marginLeft: 12 }}>
                            {prop.title}
                        </Typography>
                    </Box>
                </Box>
                {isCurrentAccountOrgManager() && prop.TeamProgressData || (teamState.myTeams && teamState.myTeams.length > 0) ||
                (teamState.orgProgress && teamState.orgProgress.some(member => member.user_id !== ruleUnitState.userShieldId))
                 ?
                 <>
                 <Hidden smDown>
                     <Box sx={{ display: "flex" }}>
                         {prop.teamName ? (
                             <CSVLink filename={`${prop.teamName}-progress.csv`} data={prop.TeamProgressData ?? []} headers={prop.csvHeaders}>
                                 <Button disabled={prop.loading || !prop.teamData} sx={{ width: 150, textTransform: "capitalize"}}><Download />&nbsp;Download CSV</Button>
                             </CSVLink>) 
                             : (
                             <CSVLink filename={"team-progress.csv"} data={prop.TeamProgressData ?? []} headers={prop.csvHeaders}>
                                 <Button disabled={prop.loading} sx={{ width: 150, textTransform: "capitalize"}}><Download />&nbsp;Download CSV</Button>
                             </CSVLink>
                         )}
                     </Box>
                 </Hidden>
                 <Hidden smUp>
                     <Box>
                         {prop.teamName ? (
                             <CSVLink filename={`${prop.teamName}-progress.csv`} data={prop.TeamProgressData ?? []} headers={prop.csvHeaders}>
                                 <IconButton disabled={prop.loading || !prop.teamData}><Download sx={{ color: theme.palette.primary.main }} /></IconButton>
                             </CSVLink>
                         ) : (
                             <CSVLink filename={"team-progress.csv"} data={prop.TeamProgressData ?? []} headers={prop.csvHeaders}>
                                 <IconButton disabled={prop.loading}><Download sx={{ color: theme.palette.primary.main }} /></IconButton>
                             </CSVLink>
                         )}
                     </Box>
                 </Hidden>
             </>
            : null}
                <Box>
                    <Box sx={{ mr: 0, mt: 2, display: "flex", justifyContent: "flex-end" }}>
                        {prop.action}
                    </Box>
                </Box>
            </Box >
            <Box sx={{ borderBottom: "1px solid lightgrey", mx: 1.5 }}></Box>
        </Box >
    );
}

export default YourTeamHeader;
