import React from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Acknowledgment } from "../../../types/models/Acknowledgement";
import { RuleInstance } from "../../../types/models/RuleInstance";
import { Fonts } from "../../../constants/AppEnums";
import { theme } from '../../../themes/theme';
import { fontColor } from "../../../constants/ColorSets";

interface MemberAlertProp {
    progress: Acknowledgment;
    ruleInstance?: RuleInstance;
    openAlertChat: (ruleInstanceId: string) => void;
}

const MemberAlert: React.FC<MemberAlertProp> = ({ progress, ruleInstance, openAlertChat }) => {
    
    const getUnreadAlert = (progress: Acknowledgment) => {
        if(!progress) {
            return null;
        }

        if(!progress.Resolved) {
            if(progress.SenderId !== null) {
                if(progress.SenderId === progress.UserId) {
                    return true;
                }
            }
            else if(progress.SenderId === null && progress.DisplayMessageCount === 1) {
                return true;
            }
        }
        else {
            return false;
        }
    }

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                p: 2,
                mt: "-1px",
                border: "1px solid lightgray",
                backgroundColor: theme.table.subHeaderBgColor,
                borderRadius: 0
            }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {progress.Resolved ? (
                    <CheckCircleOutlineOutlinedIcon
                        sx={{ color: theme.component.alertResolvedColor, fontSize: "20px" }}
                    />
                ) : (
                    <CancelOutlinedIcon
                        sx={{ color: theme.component.alertUnresolvedColor, fontSize: "20px" }}
                    />
                )}
                <Typography
                    sx={{
                        marginLeft: "5px",
                        fontWeight: Fonts.REGULAR,
                        fontSize: 14
                    }}
                >
                    {ruleInstance?.DisplayName}
                </Typography>
            </Box>
            {(progress.DisplayMessageCount > 0) && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        color: theme.palette.primary.main,
                        fontSize: "14px",
                        cursor: "pointer"
                    }}
                    onClick={() => openAlertChat(progress.RuleInstanceId)}
                >
                    <ChatBubbleOutlineIcon fontSize="small" sx={{ mt: 0.5 }} />
                    {`(${progress.DisplayMessageCount > 0 ? progress.DisplayMessageCount : null}) >`}
                    {getUnreadAlert(progress) ? <Box sx={{ml: 2, width: "10px", height: "10px", borderRadius: "50px", background: fontColor.orangeTitle}}></Box> : null}
                </Box>
            )}
        </Box>
    );
};

export default MemberAlert;