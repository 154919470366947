import React, { ReactNode } from "react";
import { Breakpoint, SxProps } from "@mui/system";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, PaperProps, Slide, Theme } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import AppScrollbar from "./AppScrollbar";
import { Fonts } from "../../constants/AppEnums";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  // eslint-disable-next-line no-undef
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AppDialogProps {
  maxWidth?: Breakpoint;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string | ReactNode;
  dividers?: boolean;
  hideClose?: boolean;
  fullHeight?: boolean;
  actionTitle?: string;
  sxStyle?: SxProps<Theme>;
  footer?: ReactNode;
  extraMenu?: ReactNode;
  floating?: ReactNode;
  paperProps?: PaperProps;
  disableBackDropOffClose?: boolean;
}

const AppDialog: React.FC<AppDialogProps> = ({
  sxStyle,
  maxWidth = "sm",
  hideClose = false,
  open,
  onClose,
  children,
  dividers = false,
  title,
  footer,
  fullHeight = false,
  extraMenu = undefined,
  floating = undefined,
  paperProps = undefined,
  disableBackDropOffClose = false,
}) => {

  const handleClose = (reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick" && disableBackDropOffClose) {
      return;
    }
    onClose();
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiDialogContent-root": {
          overflowY: "auto",
          paddingLeft: 0,
          paddingRight: 0,
        },
        ...sxStyle,
      }}
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      open={open}
      onClose={(event, reason) => handleClose(reason)}
      PaperProps={paperProps}
    >
      <DialogTitle
        display="flex"
        sx={{
          fontSize: 14,
          paddingTop: 2,
          paddingLeft: 2,
          paddingBottom: 1,
          fontWeight: Fonts.MEDIUM,
        }}
        id="app-dialog-title"
      >
        {title}
        <Box ml="auto" display="flex" alignItems="start" gap={1}>
          {extraMenu && <Box>{extraMenu}</Box>}
          {hideClose ? null : (
            <IconButton
              aria-label="close"
              sx={{
                color: "grey.500",
                m: -1,
                p: 1
              }}
              onClick={onClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <DialogContent dividers={dividers}>
        <AppScrollbar
          sx={{
            paddingTop: 1,
            height: fullHeight ? "70vh" : "100%",
            paddingRight: 3,
            paddingLeft: 3,
          }}
        >
          {children}
        </AppScrollbar>
        {
          floating && (
            <Box sx={{ position: "absolute", bottom: 16, right: 16 }}>
              {floating}
            </Box>)
        }
      </DialogContent>
      {footer ? (
        <DialogActions>
          {footer}
        </DialogActions>
      ) : null}
    </Dialog>
  );
};
export default AppDialog;
